import React, { useEffect } from 'react'

import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { withRouter } from 'react-router-dom'
import ReactModal from 'react-modal'
import ReactGTM from 'react-gtm-module'
import SocketIO from 'socket.io-client'
import Echo from 'laravel-echo'

import Window from 'types/window.d'

import { getIsMaintenanceMode } from 'state/app/selectors'
import { setIsMaintenanceMode } from 'state/app/actions'

import { echoHost } from 'config'

import deployment from 'utils/deployment'
import isServer from 'utils/isServer'
import isTest from 'utils/isTest'
import isDebug from 'utils/isDebug'

import {
  get,
  ApiResponse,
  StatusApiResponseData,
} from 'utils/apis/insuranceLounge'

import { cacheAllInsuranceTypes } from 'state/insuranceTypes/actions'
import { startCarrierQuoteEventListeners } from 'state/quotes/actions'

import { HeaderContextProvider } from 'components/Header/context'
import { MainNavContextProvider } from 'components/MainNav/context'
import { ForgetMeContextProvider } from 'components/blocks/Modal/ForgetMe/context'

import { Props } from './types'
import App from './index.presentational'
import { ScrollToTopContextProvider } from './ScrollToTop/context'

declare let window: Window

if (!isTest) {
  ReactModal.setAppElement('#root')
}

if (!isServer) {
  window.io = SocketIO

  if (typeof window.io !== 'undefined') {
    if (window.Echo) {
      window.Echo.destroy()
    }

    window.Echo = new Echo({
      broadcaster: 'socket.io',
      host: echoHost,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const wrapInContextProviders = (elem: React.ReactElement, contextProviders: any[]): React.ReactElement => {
  return contextProviders.reduce((wrappedElem, contextProvider): React.ReactElement => {
    const newWrappedElem = React.createElement(contextProvider, {}, wrappedElem)

    return newWrappedElem
  }, elem)
}

const AppContainer: React.FC<Props> = (): React.ReactElement => {
  const dispatch = useDispatch()

  // Set, get, and monitor maintenance mode status
  const isMaintenanceMode: boolean = useSelector(getIsMaintenanceMode) || false
  useEffect(() => {
    get('/status')
      .then(({ data }: ApiResponse): void => {
        if (data) {
          dispatch(setIsMaintenanceMode((data as StatusApiResponseData).web_app_maintenance_mode))
        }
      })

    window.Echo.channel('status')
      .listen('MaintenanceModeUpdated', (event: StatusApiResponseData) => {
        dispatch(setIsMaintenanceMode(event.web_app_maintenance_mode))
      })

    return (): void => {
      window.Echo.leave('status')
    }
  }, [ dispatch ])

  // Initialize google analytics + google tag manager
  useEffect(() => {
    if (deployment !== 'production') {
      return
    }

    if (process.env.RAZZLE_GOOGLE_TAG_MANANGER_CONTAINER_ID) {
      ReactGTM.initialize({ gtmId: process.env.RAZZLE_GOOGLE_TAG_MANANGER_CONTAINER_ID })
    }
  }, [ dispatch ])

  // Cache insurance types
  useEffect(() => {
    Promise.all([ dispatch(cacheAllInsuranceTypes()) ]).catch(e => {
      if (isDebug) {
        console.warn('[DEBUG] An error occurred trying to cache insurance types. Some features may not work properly.', e) // eslint-disable-line no-console
      }
    })
  }, [ dispatch ])

  dispatch(startCarrierQuoteEventListeners())

  // Wrap in context providers
  const wrappedElem = wrapInContextProviders(React.createElement(App, { isMaintenanceMode }), [
    HeaderContextProvider,
    MainNavContextProvider,
    ForgetMeContextProvider,
    ScrollToTopContextProvider,
  ])

  return wrappedElem
}

export * from './types'

export default withRouter(AppContainer)
